.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.footer-link {
  color: #364153;
  font-size: 15px;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.footer-logo {
  margin-bottom: 5px;
}

.footer-rights {
  font-size: 12px;
}

.footer-right {
  display: flex;
  align-items: center;
  gap: 20px; /* Adjust the gap between links as needed */
}

.footer-terms {
  color: #364153;
  font-size: 15px;
  text-decoration: none;
  cursor: pointer;
}

.footer-terms:hover {
  text-decoration: underline;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer-right {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
  }

  .footer-link {
    margin-bottom: 10px;
  }

  .footer-terms {
    font-size: 14px;
  }

  .footer-right img {
    align-self: center;
  }

  .footer-right img:first-child {
    margin-right: 10px;
  }

  .footer-images {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
}
