// Here you can add other styles
.maxwidth {
    width: 100%;
}

.centertext {
    text-align: center;
}

.breadcrumb-item.active {
    color: #000000
}

.invalid-input {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e55353'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23e55353' stroke='none'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    border-color: #e55353;
}

.rem1_bottom {
    margin-bottom: 1rem !important;
}

.vh80 {
    height: 80vh !important;
}

.sidebar-nav {
    background-color: #20264E;
    color: white;
}

.sidebar-brand {
    background-color: #080F3B;
}

.sidebar-toggler {
    background-color: #080F3B;
}

.bg-dark {
    background-color: #20264E !important;
}

.bg-light {
    background-color: #e6e7eb !important;
}

hr {
    margin: 0.5rem;
}

.bg-color-fondo-1 {
    background-color: #20264E;
}

.etiqueta_cash {
    color: #20264E !important;
}

.btn-primary {
    background-color: #20264E !important;
}

a {
    text-decoration: none;
}

.btn-success {
    color: white;
}

.btn-danger {
    color: white;
}

.btn-secondary {
    color: white;
}

.btn-success:hover {
    color: white;
}

.btn-danger:hover {
    color: white;
}

.btn-secondary:hover {
    color: white;
}

.ag-header {
    background-color: #20264e;
}

.ag-header-cell-text {
    color: white
}

.modal-header .btn-close {
    color: white
}

.modal-header {
    background-color: #20264e;
    color: white;
}